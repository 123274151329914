<template>
  <NPopover
    placement="bottom-end"
    trigger="click"
  >
    <template #trigger>
      <div class="ss-summary-trigger">
        <NIcon size="24" :color="themeSettings.appThemeColor">
          <AssistantPhotoOutlined />
        </NIcon>
        <h3 class="primary-color">能力分</h3>
      </div>
    </template>
    <template #default>
      <ul class="score-list" v-if="skillScoreList.length > 0">
        <li
          v-for="item in skillScoreList"
          :key="item.id"
        >
          <div class="skill-name">{{ item.label }}</div>
          <div class="skill-score primary-color">{{ Math.round(item.score * 10) / 10 }}分</div>
        </li>
      </ul>
      <NEmpty v-else description="暂无能力分" style="margin: 15px 20px;" />
    </template>
  </NPopover>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { NPopover, NEmpty, NIcon } from 'naive-ui';
  import { AssistantPhotoOutlined } from '@vicons/material';

  import themeSettings from '@/settings/theme-settings.js';
  import { listTypeTagVal } from '@/enumerators/study-situation.js'
  import { resStatusEnum } from '@/enumerators/http.js';
  import { knowledgeTaskPageTypeVal } from '@/enums/knowledge-task-page-type.js';

  import { reqList } from '@/api/planning.js';

  const props = defineProps({
    pageList: {
      type: Array,
      default: () => []
    },
    materialSkillArr: {
      type: Array,
      default: () => []
    }
  });

  const { SUCCESS } = resStatusEnum;

  function updateSkillIDScore(skillIDScore, skills) {
    if (!Array.isArray(skills)) {
      return;
    }
    skills.forEach(({ skillID, score }) => {
      skillIDScore[skillID] || (skillIDScore[skillID] = 0);
      skillIDScore[skillID] += score;
    });
  }
  const skillIDScore = computed(() => {
    const obj = {};
    props.pageList.forEach(item => {
      const { pageTypeVal } = item;
      switch (pageTypeVal) {
        case knowledgeTaskPageTypeVal.PIC_TXT:
          updateSkillIDScore(obj, item.skills);
          break;
        case knowledgeTaskPageTypeVal.VIDEO:
          updateSkillIDScore(obj, item.skills);
          break;
        case knowledgeTaskPageTypeVal.CONVERSATION:
          item.items.forEach(({ skills }) => {
            updateSkillIDScore(obj, skills);
          });
          break;
        case knowledgeTaskPageTypeVal.FORM:
          item.formItems.forEach(({ skills }) => {
            updateSkillIDScore(obj, skills);
          });
          break;
        case knowledgeTaskPageTypeVal.EXERCISES:
          updateSkillIDScore(obj, item.skills);
          break;
      }
    });
    return obj;
  });
  const skillScoreList = computed(() => {
    const materialSkillArrLink = props.materialSkillArr;
    if (materialSkillArrLink.length > 0) {
      return materialSkillArrLink.map(({ id, title, score }) => ({ id, label: title, score }));
    }

    const skillIDScoreLink = skillIDScore.value;
    const skillIDTitleLink = skillIDTitle.value;
    return Object.keys(skillIDScoreLink).map(id => ({ label: skillIDTitleLink[id], score: skillIDScoreLink[id], id }));
  });

  const skillIDTitle = ref({});
  function reqSkillList() {
    reqList({
      type: listTypeTagVal.SKILL,
      page: 1,
      page_size: 50000
    }).then(({ code, data }) => {
      const { list } = data;
      if (code === SUCCESS && Array.isArray(list)) {
        list.map(({ title, id }) => {
          skillIDTitle.value[id] = title;
        });
      }
    });
  }
  reqSkillList();
</script>

<style lang="less" scoped>
  .ss-summary-trigger {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;

    h3 {
      margin: -8px 0 0;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .score-list {
    &, & li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    li {
      position: relative;
      line-height: 32px;
      padding: 0 100px 0 0;
    }
  }
  .skill-score {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>