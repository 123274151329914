<template>
  <div class="ans-box" ref="ansBoxRef">
    <NModal
      v-if="isBoxOnMounted"
      v-model:show="modalShow"
      title="Question"
      preset="dialog"
      :show-icon="false"
      style="width: 700px;"
      :closable="false"
      :close-on-esc="false"
      :mask-closable="false"
      :to="ansBoxRef"
      positive-text="提交"
      @positive-click="handleSubmit"
    >
      <div class="ques">
        <NIcon class="ques-icon" size="33" :color="themeSettings.warningColor"><HelpOutlineOutlined /></NIcon>
        <div class="ques-txt">{{ stepData.ques }}</div>
      </div>
      <div class="ans">
        <div v-if="stepData.optionVal === conversationQuesVal.QUES_ANS">
          <NInput
            type="textarea"
          />
        </div>
        <div v-else-if="stepData.optionVal === conversationQuesVal.SELECT">
          <NCheckboxGroup>
            <div
              v-for="item in stepData.options"
              :key="item.key"
              class="checkbox-item"
            >
              <NCheckbox
                :label="`${item.label}. ${item.value}`"
                :value="item.label"
              />
            </div>
          </NCheckboxGroup>
        </div>
      </div>
    </NModal>
  </div>
</template>

<script setup>
  import { ref, onMounted, nextTick, watch } from 'vue';
  import { NModal, NIcon, NInput, NCheckboxGroup, NCheckbox } from 'naive-ui';
  import { HelpOutlineOutlined } from '@vicons/material';

  import themeSettings from '@/settings/theme-settings.js';
  import { conversationQuesVal } from '@/views/knowledge/index/knowledge-task/generate-data.js';

  const emit = defineEmits(['step-next']);
  const props = defineProps({
    stepData: {
      required: true,
      type: Object
    }
  });

  const ansBoxRef = ref(null);
  const isBoxOnMounted = ref(false);
  const modalShow = ref(false);
  function handleSubmit() {
    emit('step-next');
  }
  onMounted(() => {
    isBoxOnMounted.value = true;
    nextTick(() => {
      modalShow.value = true;
    });
  });

  watch(
    () => props.stepData,
    () => {
      nextTick(() => {
        modalShow.value = true;
      });
    }
  );
</script>

<style lang="less" scoped>
  .ans-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    :deep(.n-modal-container) {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      
      &,
      .n-modal-mask,
      .n-modal-body-wrapper {
        position: absolute;
      }
    }
  }

  .ques {
    margin-top: 20px;
  }
  .ques-icon {
    position: absolute;
    top: -5px;
  }
  .ques-txt {
    margin-left: 40px;
    font-size: 15px;
    font-weight: bold;
  }
  .ans {
    margin-top: 15px;
    padding-left: 40px;
  }
  .checkbox-item {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
</style>