<template>
  <div class="npp-trigger">
    <NDropdown
      placement="bottom-end"
      trigger="click"
      :options="options"
      @select="handleSelect"
    >
      <div>
        <NIcon size="22" :color="themeSettings.appThemeColor">
          <NoteAltOutlined />
        </NIcon>
        <h3 class="primary-color">便签</h3>
      </div>
    </NDropdown>

    <NotePadDetailModal ref="notePadDetailModalRef" />
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { NDropdown, NIcon } from 'naive-ui';
  import { NoteAltOutlined } from '@vicons/material';

  import NotePadDetailModal from './NotePadDetailModal.vue';

  import themeSettings from '@/settings/theme-settings.js';

  const props = defineProps({
    notePadArr: {
      type: Array,
      default: () => []
    }
  });

  const options = computed(() => {
    const notePadArrLink = props.notePadArr;
    if (Array.isArray(notePadArrLink)) {
      return notePadArrLink.map(({ title, content }, index) => ({
        label: title,
        key: index,
        content
      }));
    } else {
      return [];
    }
  });

  const notePadDetailModalRef = ref(null);
  function handleSelect(key, { label, content }) {
    notePadDetailModalRef.value.open({ title: label, content });
  }
</script>

<style lang="less" scoped>
  .npp-trigger {
    display: inline-block;
    vertical-align: top;
    margin: 2px 30px 0 0;
    text-align: center;
    cursor: pointer;

    h3 {
      margin: -8px 0 0;
      font-size: 12px;
      font-weight: normal;
    }
  }
</style>