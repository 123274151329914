<template>
  <div>
    <NModal
      preset="dialog"
      :show-icon="false"
      style="width: 550px;"
      :title="modalTitle"
      v-model:show="modalShow"
      positive-text="确定"
      @positive-click="handleSubmit"
      @after-leave="closeModal"
    >
      <div style="white-space: pre-wrap; word-break: break-all; word-break: break-word;">
        {{ modalContent }}
      </div>
    </NModal>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NModal } from 'naive-ui';

  const modalShow = ref(false);
  const modalTitle = ref('便签');
  const modalContent = ref('');

  function handleSubmit() {
    closeModal();
    return false;
  }
  function closeModal() {
    modalShow.value = false;
  }

  defineExpose({
    open({ title, content }) {
      modalTitle.value = title;
      modalContent.value = content;
      modalShow.value = true;
    }    
  });
</script>