<template>
  <div
    style="position: relative; overflow: hidden;"
    :style="{ width: `${width}px`, height: `${height}px` }"
  >
    <div
      class="conversation-pic-box"
      :style="{ width: `${picBoxSize.w}px`, height: `${picBoxSize.h}px`, transform: `scale(${conversationScale})` }"
    >
      <img
        class="conversation-bg"
        :src="conversationScenesValBg[pageData.scenesType]"
      />
      <img
        v-for="item in appearPeopleArr"
        :key="item"
        :src="item.inScenesPic"
        class="people-pic"
        :style="{ ...item.inScenesStyle }"
      />
      <ConversationTalk
        ref="conversationTalkRef"
        v-if="currentStepData.editBtnType === conversationEditBtnTypeVal.CHAT"
        :autoPlay="currentPageIndex === pageIndex && !playBtnShow"
        :stepData="currentStepData"
        :boxWidth="width"
        :conversationScale="conversationScale"
      />
      <PlayBtn v-if="playBtnShow" big @click="handleClickPlay" />
    </div>
    <ConversationAns
      v-if="currentStepData.editBtnType === conversationEditBtnTypeVal.QUES_ANS"
      :stepData="currentStepData"
      @step-next="nextStep"
    />
  </div>
</template>

<script setup>
  import { ref, watch, computed, onMounted } from 'vue';

  import ConversationTalk from './ConversationTalk.vue';
  import ConversationAns from './ConversationAns.vue';
  import PlayBtn from './PlayBtn.vue';

  import {
    conversationEditBtnTypeVal,
    conversationScenesValBg,
    conversationPeopleVal,
    conversationPeopleValName,
    conversationScenesValPeopleValPeople
  } from '@/views/knowledge/index/knowledge-task/generate-data.js';

  const emit = defineEmits(['slide-next']);
  const props = defineProps({
    useFor: {
      required: true,
      type: String
    },
    pageIndex: {
      required: true,
      type: Number
    },
    pageData: {
      required: true,
      type: Object
    },
    currentPageIndex: {
      required: true,
      type: Number
    },

    width: {
      required: true,
      type: Number
    },
    height: {
      required: true,
      type: Number
    }
  });

  const picBoxSize = {
    w: 1920,
    h: 1080
  };
  const conversationScale = ref(1);
  watch(
    () => props.width,
    val => {
      conversationScale.value = val / picBoxSize.w;
    },
    { immediate: true }
  );

  const currentStepIndex = ref(0);
  const currentStepData = ref({});
  function updateCurrentStep(index) {
    currentStepIndex.value = index;
    currentStepData.value = stepArr[index];
  }
  function nextStep() {
    const currentIndex = currentStepIndex.value;
    if (currentIndex < stepArr.length - 1) {
      updateCurrentStep(currentIndex + 1);
    } else {
      console.log('结束');
      emit('slide-next');
    }
  }

  const hadPlayed = ref(false);
  const firstItemIsChat = ref(false);
  const playBtnShow = computed(() => {
    return props.pageIndex === 0 && firstItemIsChat.value && !hadPlayed.value;
  });
  const conversationTalkRef = ref(null);
  function handleClickPlay() {
    const stepData = currentStepData.value;
    if (!stepData) {
      return;
    }
    if (stepData.editBtnType !== conversationEditBtnTypeVal.CHAT) {
      return;
    }
    conversationTalkRef.value.play();

    hadPlayed.value = true;
  }
  function pauseChat(isInit) {
    const stepData = currentStepData.value;
    if (!stepData) {
      return;
    }
    if (stepData.editBtnType !== conversationEditBtnTypeVal.CHAT) {
      return;
    }
    conversationTalkRef.value.pause();

    isInit || (hadPlayed.value = true);
  }

  let appearPeopleArr = [];
  const stepArr = [];
  function initData() {
    const appearPeopleValArr = [];
    const scenesType = props.pageData.scenesType;

    props.pageData.items.forEach(({ editBtnType, optionVal, talkTxt, talkFile, ques, ans, options }) => {
      if (editBtnType === conversationEditBtnTypeVal.CHAT) {
        if (![conversationPeopleVal.NARRATION, conversationPeopleVal.NARRATION_WIN].includes(optionVal)) {
          appearPeopleValArr.includes(optionVal) || appearPeopleValArr.push(optionVal);
        }
        
        const talkAudio = document.createElement('audio');
        talkAudio.src = talkFile.fileUrl;

        // talkAudio.controls = true;
        // document.body.appendChild(talkAudio);
        
        const people = [conversationPeopleVal.NARRATION, conversationPeopleVal.NARRATION_WIN].includes(optionVal) ?
          { name: conversationPeopleValName[optionVal], optionVal } :
          {
            name: conversationPeopleValName[optionVal],
            optionVal,
            ...conversationScenesValPeopleValPeople[`${scenesType}-${optionVal}`]
          };
        stepArr.push({
          editBtnType,
          people,
          talkTxt,
          talkAudio: talkFile.fileUrl ? talkAudio : null,
          talkAudioPlayEndedCb: () => { nextStep(); }
        });

        if (stepArr.length === 1) {
          firstItemIsChat.value = true;
        }
      } else if (editBtnType === conversationEditBtnTypeVal.QUES_ANS) {
        stepArr.push({
          editBtnType,
          optionVal,
          ques,
          ans,
          options
        });
      }
    });

    appearPeopleArr.splice(0);
    appearPeopleValArr.forEach(item => {
      const data = conversationScenesValPeopleValPeople[`${scenesType}-${item}`];
      data && appearPeopleArr.push(data);
    });

    updateCurrentStep(0);
  }
  initData();

  onMounted(() => {
    if (props.currentPageIndex === props.pageIndex && !playBtnShow.value) {
      handleClickPlay();
    } else {
      pauseChat(true);
    }
  });

  watch(
    () => props.currentPageIndex,
    val => {
      const b = val === props.pageIndex;
      if (b) {
        console.log('对话播放');
        if (currentStepIndex.value === 0) {
          handleClickPlay();
        } else {
          updateCurrentStep(0);
        }
      } else {
        console.log('对话暂停');
        pauseChat();
      }
    }
  );
</script>

<style lang="less" scoped>
  .conversation-pic-box {
    position: relative;
    transform-origin: left top;
  }

  .conversation-bg {
    display: block;
  }

  .people-pic {
    position: absolute;
  }  
</style>