<template>
  <div class="task-box" :style="{ minWidth: `${detailSize.w + (arrowSize + 15) * 2}px` }">
    <NCard
      class="task-detail-card"
      size="small"
      :segmented="{ content: true }"
      :style="{ visibility: pageInited ? 'visible' : 'hidden', width: `${detailSize.w}px` }"
    >
      <template #header>
        <div style="margin: -4px -1px;">{{ knowledgeTitle }}</div>
      </template>
      <template #header-extra>
        <div style="margin: -4px -1px; white-space: nowrap;">
          <NotePadPreview
            v-if="notePadPreviewShow"
            :notePadArr="taskData.pageList[currentPageIndex].notePadArr"
          />
          <SkillScoreSummary :pageList="taskData.pageList" />
        </div>
      </template>
      <template #default>
        <NCarousel
          class="carousel-box"
          effect="fade"
          :style="{ height: `${detailSize.h}px` }"
          :loop="false"
          :touchable="false"
          :show-dots="false"
          v-model:current-index="currentPageIndex"
        >
          <SlideItem
            v-for="(item, index) in taskData.pageList"
            :key="item.key"
            :height="detailSize.h"
          >
            <component
              :is="comp[item.pageTypeVal]"
              :useFor="useFor"
              :pageIndex="index"
              :pageData="item"
              :currentPageIndex="currentPageIndex"

              :width="detailSize.w"
              :height="detailSize.h"

              @submit-btn-click="handleSubmitBtnClick"
              @slide-next="handleClickNext"
              @play-ended="handlePlayEnded"
            ></component>
          </SlideItem>
        </NCarousel>
      </template>
    </NCard>
    <div
      v-show="currentPageIndex > 0"
      class="prev-btn"
      :style="{ marginLeft: `-${detailSize.w / 2 + arrowSize + 10}px` }"
      @click="handleClickPrev"
    >
      <NIcon size="60" color="#666"><ArrowBackIosOutlined /></NIcon>
    </div>
    <div
      v-show="currentPageIndex < taskData.pageList.length - 1 "
      class="next-btn"
      :style="{ marginLeft: `${detailSize.w / 2 + 10}px` }"
      @click="handleClickNext"
    >
      <NIcon size="60" color="#666"><ArrowForwardIosOutlined /></NIcon>
    </div>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, computed, onMounted, onBeforeUnmount } from 'vue';
  import { useRoute } from 'vue-router';
  import { NCard, NCarousel, NIcon } from 'naive-ui';
  import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@vicons/material';

  import PageLoading from '@/components/PageLoading/index.vue';
  import SkillScoreSummary from '@/views/knowledge/index/knowledge-task/components/SkillScoreSummary.vue';
  import SlideItem from './Slides/SlideItem.vue';
  import FormSlide from './Slides/FormSlide.vue';
  import ConversationSlide from './Slides/ConversationSlide.vue';
  import VideoSlide from './Slides/VideoSlide.vue';
  import PicTxtSlide from './Slides/PicTxtSlide.vue';
  import ExercisesSlide from './Slides/ExercisesSlide.vue';
  import NotePadPreview from './NotePadPreview/NotePadPreview.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { generateDefaultTaskData, generateTaskData, exercisesContentExerciseIDtoData } from '@/views/knowledge/index/knowledge-task/generate-data.js';
  import { knowledgeTaskPageTypeVal } from '@/enums/knowledge-task-page-type.js';

  import { reqKnowledgeTaskDetail } from '@/api/knowledge.js'

  const props = defineProps({
    useFor: { // preview teaching
      required: true,
      type: String
    },
    knowledgeName: {
      type: String,
      default: ''
    },
    autoInit: {
      type: Boolean,
      default: true
    }
  });

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const route = useRoute();

  const detailSize = reactive({
    w: 1920,
    h: 1080
  });
  const pageInited = ref(false);
  let knowledgeID = Number(route.query.id) || 0;
  const knowledgeTitle = ref('知识点');
  const taskData = reactive(generateDefaultTaskData(knowledgeID));

  function initKnowledgeInfo() {
    if (!knowledgeID) {
      // 预览
      const savedTaskData = window.localStorage.getItem('a-knowledge-preview');
      if (savedTaskData) {
        pageInited.value = true;
        Object.assign(taskData, JSON.parse(savedTaskData));
      }
      return;
    }
    loading.value = true;
    reqKnowledgeTaskDetail({
      'Knowledge[id]': knowledgeID
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        pageInited.value = true;
        knowledgeTitle.value = props.knowledgeName || data.title;
        Object.assign(taskData, generateTaskData(knowledgeID, data, { generatePreviewData: true }));
        exercisesContentExerciseIDtoData(taskData);
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  const comp = {};
  comp[knowledgeTaskPageTypeVal.FORM] = FormSlide;
  comp[knowledgeTaskPageTypeVal.CONVERSATION] = ConversationSlide;
  comp[knowledgeTaskPageTypeVal.VIDEO] = VideoSlide;
  comp[knowledgeTaskPageTypeVal.PIC_TXT] = PicTxtSlide;
  comp[knowledgeTaskPageTypeVal.EXERCISES] = ExercisesSlide;

  function updateDetailSize() {
    const h = window.innerHeight - 15 * 2 - 58;
    const w = 1920 / 1080 * h;
    Object.assign(detailSize, {
      w,
      h
    });
  }
  onMounted(() => {
    props.autoInit && initKnowledgeInfo();
    updateDetailSize();
    window.addEventListener('resize', updateDetailSize);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateDetailSize);
  });

  const arrowSize = 60;
  const currentPageIndex = ref(0);
  let pageIndexBeforeJumpArr = [];
  function handleClickPrev() {
    if (pageIndexBeforeJumpArr.length > 0) {
      currentPageIndex.value = pageIndexBeforeJumpArr.pop();
    } else {
      const srcIndex = currentPageIndex.value;
      if (srcIndex <= 0) {
        return;
      }
      currentPageIndex.value--;
    }
  }
  function handleClickNext() {
    const srcIndex = currentPageIndex.value;
    if (srcIndex >= taskData.pageList.length - 1) {
      return;
    }
    pageIndexBeforeJumpArr.push(currentPageIndex.value);
    currentPageIndex.value++;
  }
  function handleSubmitBtnClick(behaviour) {
    const srcIndex = currentPageIndex.value;
    switch (behaviour) {
      case 'prev':
        if (srcIndex <= 0) {
          return;
        }
        pageIndexBeforeJumpArr.push(currentPageIndex.value);
        currentPageIndex.value--;
        break;
      case 'next':
        if (srcIndex >= taskData.pageList.length - 1) {
          return;
        }
        pageIndexBeforeJumpArr.push(currentPageIndex.value);
        currentPageIndex.value++;
        break;
      default:
        if (behaviour < 0 || behaviour > taskData.pageList.length - 1) {
          return;
        }
        pageIndexBeforeJumpArr.push(currentPageIndex.value);
        currentPageIndex.value = behaviour;
    }
  }

  function handlePlayEnded() {
    setTimeout(() => {
      handleClickNext();
    }, 1000);
  }

  const notePadPreviewShow = computed(() => {
    const currentPageData = taskData.pageList[currentPageIndex.value];
    if (!currentPageData) {
      return false;
    }
    const { pageTypeVal, notePadArr } = currentPageData;
    return pageTypeVal === knowledgeTaskPageTypeVal.FORM && Array.isArray(notePadArr) && notePadArr.length > 0;
  });

  defineExpose({
    manualInit(id) {
      knowledgeID = id;
      initKnowledgeInfo();
    }
  });
</script>

<style lang="less" scoped>
  .task-box {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .task-detail-card {
    box-sizing: content-box;

    :deep(.n-card__content) {
      padding: 0;
    }
  }

  .carousel-box {
    &.n-carousel--fade {
      :deep(.n-carousel__slide.n-carousel__slide--current) {
        z-index: 1;
      }
    }
  }

  .prev-btn, .next-btn {
    position: absolute;
    top: 45%;
    left: 50%;
    cursor: pointer;
    opacity: .5;
    transition: opacity .3s;

    &:hover {
      opacity: .8;
    }
  }
</style>