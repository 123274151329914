<template>
  <div v-show="talkShow">
    <ConversationTalkWin
      v-if="stepData.people.optionVal === conversationPeopleVal.NARRATION_WIN"
      ref="conversationTalkWinRef"
      :people="stepData.people"
      :talkTxt="stepData.talkTxt"
      :boxWidth="boxWidth"
      :conversationScale="conversationScale"
    />
    <ConversationTalkDanmu
      v-else
      :people="stepData.people"
      :talkTxt="stepData.talkTxt"
      :boxWidth="boxWidth"
      :conversationScale="conversationScale"
    />
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';

  import ConversationTalkDanmu from './ConversationTalkDanmu.vue';
  import ConversationTalkWin from './ConversationTalkWin.vue';

  import { conversationPeopleVal } from '@/views/knowledge/index/knowledge-task/generate-data.js';

  const props = defineProps({
    autoPlay: {
      type: Boolean,
      default: true
    },
    stepData: {
      required: true,
      type: Object
    },
    boxWidth: {
      required: true,
      type: Number
    },
    conversationScale: {
      type: Number,
      default: 1
    }
  });

  const talkShow = ref(false);
  const conversationTalkWinRef = ref(null);
  const displayTxt = ref('');
  let intervalID = null;
  function stepDisplayTxt(totalSeconds) {
    displayTxt.value = '';
    const totalMilliseconds = Math.floor(totalSeconds * 1000);
    const txt = props.stepData.talkTxt;
    const txtLength = txt.length;
    const interval = Math.floor(totalMilliseconds / txtLength);
    let index = 1;
    clearInterval(intervalID);
    intervalID = setInterval(() => {
      displayTxt.value = txt.slice(0, index++);
      if (index > txtLength) {
        clearInterval(intervalID);
      }
    }, interval);
  }

  const millisecondsBeforePlay = 2000;
  const millisecondsAfterPlay = 2000;
  let closeTalkWinTimerID;
  let playEndedCbTimerID;
  let playTimerID;
  function play() {
    console.log('play audio');
    const data = props.stepData;
    const audio = data.talkAudio;
    if (audio) {
      talkShow.value = true;
      audio.onended = () => {
        if (data.people.optionVal === conversationPeopleVal.NARRATION_WIN) {
          closeTalkWinTimerID = setTimeout(() => {
            conversationTalkWinRef.value.close();
          }, millisecondsAfterPlay - 400);
        }
        playEndedCbTimerID = setTimeout(() => {
          talkShow.value = false;
          data.talkAudioPlayEndedCb();
        }, millisecondsAfterPlay);
      };
      audio.currentTime = 0;
      playTimerID = setTimeout(() => {
        // stepDisplayTxt(audio.duration);
        audio.play();
      }, millisecondsBeforePlay);
    } else {
      talkShow.value = true;
      playTimerID = setTimeout(() => {
        if (data.people.optionVal === conversationPeopleVal.NARRATION_WIN) {
          closeTalkWinTimerID = setTimeout(() => {
            conversationTalkWinRef.value.close();
          }, millisecondsAfterPlay - 400);
        }
        playEndedCbTimerID = setTimeout(() => {
          talkShow.value = false;
          data.talkAudioPlayEndedCb();
        }, millisecondsAfterPlay);
      }, millisecondsBeforePlay + 3000);
    }
  }
  function pause() {
    console.log('pause audio');
    clearTimeout(closeTalkWinTimerID);
    clearTimeout(playEndedCbTimerID);
    clearTimeout(playTimerID);
    const data = props.stepData;
    const audio = data.talkAudio;
    if (audio) {
      talkShow.value = false;
      audio.pause();
    } else {
      talkShow.value = false;
    }
  }
  watch(
    () => props.stepData,
    () => {
      console.log('auto play', props.autoPlay);
      props.autoPlay && play();
    },
    { immediate: true }
  );
  defineExpose({
    play,
    pause
  });
</script>