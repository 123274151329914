import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function reqList(data) {
  return request({
    url: '/backend/web/planning/index',
    method: GET,
    params: data
  });
}

export function createItem(data) {
  return request({
    url: '/backend/web/planning/create',
    method: POST,
    data
  });
}

export function editItem(data) {
  return request({
    url: '/backend/web/planning/update',
    method: POST,
    data
  });
}

export function delItem(data) {
  return request({
    url: '/backend/web/planning/delete',
    method: POST,
    data
  });
}

export function reqItemDetail(data) {
  return request({
    url: '/backend/web/planning/detail',
    method: POST,
    data
  });
}