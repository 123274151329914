<template>
  <div>
    <ExerciseItemView
      v-for="(item, index) in exerciseViewArr"
      :key="item.id"
      :exerciseIndex="index"
      :exerciseData="item"
    />
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import ExerciseItemView from '@/views/material/preview/components/ExerciseItem.vue';

  import { generateExerciseViewItemData } from '@/views/knowledge/index/knowledge-task/generate-data.js';

  const props = defineProps({
    useFor: {
      required: true,
      type: String
    },
    pageIndex: {
      required: true,
      type: Number
    },
    pageData: {
      required: true,
      type: Object
    },
    currentPageIndex: {
      required: true,
      type: Number
    }
  });

  const exerciseViewArr = computed(() => {
    const exerciseArr = props.pageData.exercises;
    if (Array.isArray(exerciseArr)) {
      return exerciseArr.map(item => generateExerciseViewItemData(item));
    } else {
      return [];
    }
  });
</script>