<template>
  <div
    style="position: relative;"
    :style="{ height: `${height}px` }"
  >
    <VideoPlayer
      ref="videoPlayerRef"
      @play-ended="emit('play-ended')"
    />
    <PlayBtn
      v-if="playBtnShow"
      style="z-index: 1100;"
      @click="handleClickPlay"
    />
  </div>
</template>

<script setup>
  import { ref, nextTick, onMounted, watch, computed } from 'vue';

  import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue';
  import PlayBtn from './PlayBtn.vue';

  const emit = defineEmits(['play-ended']);
  const props = defineProps({
    useFor: {
      required: true,
      type: String
    },
    pageIndex: {
      required: true,
      type: Number
    },
    pageData: {
      required: true,
      type: Object
    },
    currentPageIndex: {
      required: true,
      type: Number
    },

    height: {
      required: true,
      type: Number
    }
  });

  const videoPlayerRef = ref(null);
  onMounted(() => {
    videoPlayerRef.value.initVideo(props.pageData.videoData.videoId);
  });

  watch(
    () => props.currentPageIndex,
    val => {
      nextTick(() => {
        const b = val === props.pageIndex;
        if (b) {
          if (!playBtnShow.value) {
            console.log('播放');
            videoPlayerRef.value.replay();
          } else {
            videoPlayerRef.value && videoPlayerRef.value.pause();
          }
        } else {
          console.log('暂停');
          hadPlayed.value = true;
          videoPlayerRef.value.pause();
        }
      });
    },
    { immediate: true }
  );

  const hadPlayed = ref(false);
  const playBtnShow = computed(() => {
    return props.pageIndex === 0 && !hadPlayed.value;
  });
  function handleClickPlay() {
    videoPlayerRef.value.replay();
    hadPlayed.value = true;
  }
</script>